<template>
    <div class="box">
        <div class="printInfo" v-if="orderInfo">
            <div class="title">订单信息</div>
            <el-table :data="orderInfo.extend_order_goods" :header-cell-style="{ 'background-color': '#F8F9FA' }">
                <el-table-column prop="goods_img" label="商品图片" v-if="orderInfo.is_showGoods" width="100">
                    <template slot-scope="scope">
                        <el-image :src="scope.row.goods_img" style="width: 50px; height: 50px"></el-image>
                    </template>
                </el-table-column>
                <el-table-column prop="goods_title" label="商品名称"></el-table-column>
                <el-table-column prop="sku_list.title" label="规格">
                    <template slot-scope="scope">
                        {{ scope.row.sku_list ? scope.row.sku_list.title : '--' }}
                    </template>
                </el-table-column>
                <el-table-column prop="goods_price" label="商品单价" v-if="orderInfo.is_goodsPrice"></el-table-column>
                <el-table-column prop="goods_num" label="数量"></el-table-column>
                <el-table-column prop="goods_pay_price" label="商品总价"></el-table-column>
            </el-table>
            <div class="goods_price">
                <p>
                    <span v-show="orderInfo.is_totalPrice">商品总价：￥{{ orderInfo.goods_amount }}</span>
                    <span v-show="orderInfo.is_freight">运费：￥{{ Number(orderInfo.revise_freight_fee) > 0 ?
                        orderInfo.revise_freight_fee : orderInfo.freight_fee }}</span>
                    <span>订单总金额：￥{{ Number(orderInfo.revise_amount) > 0 ? orderInfo.revise_amount : orderInfo.amount
                    }}</span>
                    <template v-if="orderInfo.is_discount">
                        <span>优惠券减：￥{{ orderInfo.reduction_amount || '0.00' }}</span>
                        <span>会员减：￥{{ orderInfo.discount_reduction_amount || '0.00' }}</span>
                    </template>
                    <span>
                        支付方式：{{
                            orderInfo.payment_code == 10
                            ? '微信支付'
                            : orderInfo.payment_code == 20
                                ? '支付宝支付'
                                : orderInfo.payment_code == 30
                                    ? '华为支付'
                                    : orderInfo.payment_code == 40
                                        ? '余额支付'
                                        : '会员卡支付'
                        }}
                    </span>
                </p>
            </div>
            <div class="order-info">
                <div class="message" v-show="orderInfo.is_message">商家备注：{{ orderInfo.system_message || '--' }}</div>
                <div class="code">
                    <div class="left">
                        <div>
                            <p>订单编号：{{ orderInfo.pay_sn }}</p>
                            <p>下单时间：{{ orderInfo.create_time }}</p>
                        </div>
                        <div>
                            <p>手机号：{{ orderInfo.extend_order_extend.reciver_infos.phone }}</p>
                            <p>收货地址：{{ orderInfo.extend_order_extend.reciver_infos.combine_detail }}{{
                                orderInfo.extend_order_extend.reciver_infos.address }}</p>
                        </div>
                    </div>
                    <div class="right">
                        <el-image :src="'data:image/png;base64,' + orderInfo.sn_bar_code"></el-image>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { getDateformat } from '@/util/getDate';
export default {
    data () {
        return {};
    },
    computed: {
        ...mapState({
            orderInfo: state => state.order.orderInfo.info,
        }),
    },
    watch: {
        orderInfo (val) {
            let printCon = localStorage.getItem('printCon') || ['商品图片', '商品单价', '商品总价', '优惠金额', '快递运费', '商家备注'];
            if (typeof printCon == 'string') {
                printCon = JSON.parse(printCon);
            }
            for (let i in printCon) {
                if (printCon[i] == '商品图片') {
                    this.orderInfo.is_showGoods = !0;
                }
                if (printCon[i] == '商品单价') {
                    this.orderInfo.is_goodsPrice = !0;
                }
                if (printCon[i] == '商品总价') {
                    this.orderInfo.is_totalPrice = !0;
                }
                if (printCon[i] == '优惠金额') {
                    this.orderInfo.is_discount = !0;
                }
                if (printCon[i] == '快递运费') {
                    this.orderInfo.is_freight = !0;
                }
                if (printCon[i] == '商家备注') {
                    this.orderInfo.is_message = !0;
                }
            }
        },
    },
    created () {
        let id = this.$route.query.id;
        let industryName = this.$route.query.industryName || '';
        this.$store.dispatch('order/getOrderInfo', { id: id, industryName: industryName });
    },
    methods: {
        getDateformat (val) {
            return getDateformat(val);
        },
    },
};
</script>

<style lang="less" scoped>
.box {
    height: 100%;
    width: 100%;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.printInfo {
    width: 1100px;
    font-size: 14px;

    .title {
        line-height: 80px;
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        background: #f3f3f3;
    }

    .goods_price {
        color: #7b7b7c;
        padding: 0 30px 0 20px;
        display: flex;
        justify-content: flex-end;
        height: 50px;
        background: #eaedf1;
        align-items: center;

        p span {
            margin-right: 20px;
        }
    }

    .order-info {
        padding: 50px 30px 50px 20px;
        background: #f3f3f3;
        color: #909399;

        .code {
            margin-top: 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .left div {
                display: flex;
                margin-bottom: 20px;

                p:first-child {
                    margin-right: 40px;
                }
            }

            .right .el-image {
                width: 300px;
                height: 70px;
            }
        }
    }
}
</style>
